import React, { useState } from "react"; // Import useState
import { useForm, Controller } from "react-hook-form";
import { Input } from "../../../components/ui/input";
import { Checkbox } from "../../../components/ui/checkbox";
import { Label } from "../../../components/ui/label";
import { Button } from "../../../components/ui/button";
import Select from "react-select"; // Import react-select
import { useMutation } from "@tanstack/react-query";
import { postData, postFileData } from "../../../services/apiService"; // Ensure postFileData is imported
import { toast } from "react-toastify";

const CategoryCreateForm = ({
  parentCategory,
  isModalOpen,
  closeModal,
}: any) => {
  const { register, handleSubmit, control, reset } = useForm();
  const [categoryImage, setCategoryImage] = useState<File | null>(null); // State for storing the selected image

  // Mutation to send data to the backend
  const { mutate } = useMutation({
    mutationFn: (data: any) => postData("category/create-new-category", data),
    onSuccess: (response) => {
      toast.success("Category created successfully");
      reset(); // Reset the form after successful submission
      closeModal(); // Close the modal after form submission
    },
    onError: (error) => {
      console.error("Error creating category:", error);
    },
  });

  // Mutation to upload the image
  const uploadProfilePic = async () => {
    const formData = new FormData();
    if (categoryImage) {
      formData.append("profile", categoryImage);
      const response = await postFileData("/single-image-upload", formData);

      return response.data; // Assume the server returns the file URL or ID
    }
    return null;
  };

  // Transform parentCategory prop into select options
  const parentCategoryOptions = parentCategory.map((category: any) => ({
    value: category?.cat_id, // Use the cat_id as value
    label: category?.cat_title, // Use the cat_title as label
  }));

  // Handle form submission
  const onSubmit = async (data: any) => {
    const uploadedImage = await uploadProfilePic(); // Upload the image and get the URL or ID

    const categoryData = {
      parent_id: data.parentCategory?.value || 0, // Get the selected parent category value
      cat_title: data.categoryName,
      cat_slug: data.catSlug,
      cat_related_keywords: data.relatedKeywords,
      is_featured: data.featured ? 1 : 0,
      cat_seo_title: data.seoTitle,
      cat_seo_desc: data.seoDescription,
      cat_seo_keywords: data.seoKeywords,
      img: uploadedImage?.outputPath, // Include the uploaded image in the category data
    };

    // Call mutate to send data to backend
    // mutate(categoryData);
  };

  return (
    <div className="">
      <h2 className="text-xl font-semibold mb-4">Category Create Form</h2>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className="grid grid-cols-1 md:grid-cols-2 gap-4" // Updated gap for better spacing
      >
        {/* Parent Category (React Select) */}
        <div>
          <Label htmlFor="parentCategory">Parent Category</Label>
          <Controller
            name="parentCategory"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                options={parentCategoryOptions}
                isClearable
                placeholder="Select Parent Category"
                className="mt-1"
              />
            )}
          />
        </div>

        {/* Category Name (Text field) */}
        <div>
          <Label htmlFor="categoryName">Category Name</Label>
          <Input
            type="text"
            placeholder="Category Name"
            {...register("categoryName")}
            className="mt-1 w-full"
          />
        </div>

        {/* Category Slug (Text field) */}
        <div>
          <Label htmlFor="catSlug">Category Slug</Label>
          <Input
            type="text"
            placeholder="Category Slug"
            {...register("catSlug")} // Register the new input
            className="mt-1 w-full"
          />
        </div>

        {/* Image Upload (File input) */}
        <div>
          <Label htmlFor="categoryImage">Upload Image</Label>
          <Input
            type="file"
            accept="image/*"
            onChange={(e) => {
              if (e.target.files) {
                setCategoryImage(e.target.files[0]); // Set the selected image file
              }
            }}
            className="mt-1 w-full"
          />
        </div>

        {/* Related Keywords (Text field) */}
        <div>
          <Label htmlFor="relatedKeywords">Related Keywords</Label>
          <Input
            type="text"
            placeholder="Related Keywords (comma separated)"
            {...register("relatedKeywords")}
            className="mt-1 w-full"
          />
        </div>

        {/* Featured (Checkbox) */}
        <div className="mt-4">
          <div className="flex items-center space-x-2 mb-2">
            <Checkbox {...register("featured")} id="featured" />
            <Label htmlFor="featured" className="ml-2">
              Featured
            </Label>
          </div>
          <div>
            <p className="text-xs text-gray-500 mt-1">
              Select this checkbox to mark the category as featured.
            </p>
          </div>
        </div>

        {/* SEO Title (Text field) */}
        <div>
          <Label htmlFor="seoTitle">SEO Title</Label>
          <Input
            type="text"
            placeholder="SEO Title"
            {...register("seoTitle")}
            className="mt-1 w-full"
          />
        </div>

        {/* SEO Description (Text field) */}
        <div>
          <Label htmlFor="seoDescription">SEO Description</Label>
          <Input
            type="text"
            placeholder="SEO Description"
            {...register("seoDescription")}
            className="mt-1 w-full"
          />
        </div>

        {/* SEO Keywords (Text field) */}
        <div>
          <Label htmlFor="seoKeywords">SEO Keywords</Label>
          <Input
            type="text"
            placeholder="SEO Keywords (comma separated)"
            {...register("seoKeywords")}
            className="mt-1 w-full"
          />
        </div>

        {/* Submit & Reset Buttons */}
        <div className="col-span-1 md:col-span-2 flex gap-4">
          <Button
            type="button"
            onClick={() => reset()} // Reset form values
            className="w-full mt-4 bg-gray-400"
          >
            Reset
          </Button>
          <Button type="submit" className="w-full mt-4 bg-orange-500">
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
};

export default CategoryCreateForm;
