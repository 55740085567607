import { BASE_IMAGE } from "../../../services/apiService";
import Loader from "../../../common/Loader/Loader";
import { format } from "date-fns";
import { Eye } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addBreadcrumb } from "../../../common/Slice/BreadcrumbsSlice";
import { MdOutlineModeEdit } from "react-icons/md";
import EditParentCategoryModal from "./EditParentCategoryModal"; // Adjust the path accordingly
import { useState } from "react";

const CategoryTable = ({ categories, isLoading }: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);

  // Function to format date
  const formatDate = (dateString: any) => {
    return dateString
      ? format(new Date(dateString), "dd MMM yy • hh:mm a")
      : "N/A";
  };

  const BreadCrumbObject: any = {
    title: "Child Category",
    url: `/child-category`,
  };

  const handleChildCategoryView = (parentCategoryID: any) => {
    dispatch(addBreadcrumb(BreadCrumbObject));
    navigate(`/child-category?parent_cat=${parentCategoryID}`);
  };

  const handleEditCategory = (category: any) => {
    // dispatch(addBreadcrumb(BreadCrumbObject));
    setSelectedCategory(category);
    setIsModalOpen(true); // Open the modal
  };

  return (
    <div className="overflow-x-auto rounded-md shadow-md mt-6">
      {/* Show loader if loading, otherwise show table */}
      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <Loader />
        </div>
      ) : (
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-white">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-[#8E8E8E] uppercase tracking-wider">
                Parent Category
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-[#8E8E8E] uppercase tracking-wider">
                Created At
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-[#8E8E8E] uppercase tracking-wider">
                Action
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {categories.length > 0 ? (
              categories.map((category: any, index: any) => (
                <tr key={index}>
                  <td className="px-6 py-4 whitespace-normal text-sm text-gray-500 flex items-center gap-4">
                    <img
                      src={`${BASE_IMAGE}${category?.cat_img}`}
                      className="w-10 h-10 rounded-full"
                      alt=""
                    />
                    {category.cat_title || "N/A"}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {formatDate(category.cat_date_time)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-black flex gap-3 items-center cursor-pointer">
                    {/* Eye Icon for Viewing */}
                    <div
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent the click event from bubbling up
                        handleChildCategoryView(category?.cat_id); // Call the function to handle view action
                      }}
                      className="hover:text-red-600" // Change color on hover
                    >
                      <Eye size={20} />
                    </div>

                    {/* Edit Icon for Editing */}
                    <div
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent the click event from bubbling up
                        handleEditCategory(category); // Call the function to handle edit action
                      }}
                      className="hover:text-green-600" // Change color on hover
                    >
                      <MdOutlineModeEdit size={20} />
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={3}
                  className="px-6 py-4 text-center text-sm text-gray-500"
                >
                  No categories available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      )}

      {/* Edit Parent Category Modal */}
      <EditParentCategoryModal
        isOpen={isModalOpen}
        close={() => setIsModalOpen(false)}
        category={selectedCategory}
      />
    </div>
  );
};

export default CategoryTable;
