import React from "react";
import { SidebarItem as SidebarItemType } from "../../types";
import { FaHome, FaFolder, FaUserFriends, FaUser } from "react-icons/fa"; // Importing from react-icons
import { FolderPlus } from "lucide-react";
import { BiSupport } from "react-icons/bi";
import { VscReport } from "react-icons/vsc";
import { MdCallEnd } from "react-icons/md";
import { BiCategory } from "react-icons/bi";
const SidebarItems: () => (SidebarItemType | { header: string })[] = () => {
  const items = [
    {
      name: "Dashboard",
      icon: <FaHome />,
      path: "/dashboard",
    },
    { header: "USER MANAGEMENT" },
    {
      name: "All Antoilers",
      icon: <FaUser />,
      path: "/all-antoilers",
    },
    {
      name: "All Users",
      icon: <FaUserFriends />,
      path: "/all-users",
    },
    {
      name: "Create Category",
      icon: <BiCategory />,
      path: "/create-category",
    },
    { header: "RATE CARD MANAGEMENT" },
    {
      name: "All Rate Cards",
      icon: <FolderPlus size={20} />,
      path: "/all-rate-cards",
    },
    { header: "SLIDER MANAGEMENT" },
    {
      name: "All Sliders",
      icon: <FaFolder />,
      path: "/all-sliders",
    },
    { header: "OTHER" },
    {
      name: "Support",
      icon: <BiSupport />,
      path: "/support",
    },
    {
      name: "Report",
      icon: <VscReport />,
      path: "/report",
    },
    {
      name: "Answer",
      icon: <MdCallEnd />,
      path: "/answer",
    },
  ];

  return items;
};

export default SidebarItems;
