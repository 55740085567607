// apiService.js
import axios from "axios";

export const BASE_URL = "https://ant-admin-api.techcarrel.in/api";
// export const BASE_URL = "http://localhost:6010/api";
export const BASE_IMAGE = "https://ant-img.techcarrel.in/";

export const getData = async (endpoint: any) => {
  const response = await axios.get(`${BASE_URL}/${endpoint}`);
  return response;
};

export const postData = async (endpoint: string, data?: any, params?: any) => {
  const response = await axios.post(`${BASE_URL}/${endpoint}`, data, {
    params,
  });
  return response;
};

export const postFileData = async (endpoint: any, data: any) => {
  const response = await axios.post(`${BASE_IMAGE}${endpoint}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response;
};
