import React, { ReactNode, useState } from "react";
import SideBar from "./SideBar";
import { useLocation } from "react-router-dom";
import BreadcrumbLogic from "../../../common/Slice/BreadCrumbsLogic";

interface LayoutProps {
  children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const location = useLocation();
  const path = location.pathname;

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar Section */}
      <div
        className={`transition-all duration-300 
          ${
            path === "/antoiler-create-account" ||
            path === "/all-customer-list-view-mobile"
              ? "hidden md:block" // Hide sidebar on these specific routes in mobile
              : ""
          }
          ${isSidebarOpen ? "w-64" : "w-16"}`} // Adjusted to use width instead of col-span
      >
        <SideBar
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
        />
      </div>

      {/* Main Content Section */}
      <div className="flex-1 flex flex-col bg-[#FAFAFB] p-6 transition-all duration-300 h-screen overflow-auto">
        <div className="mb-4">
          {/* Breadcrumb logic hidden on specific routes */}
          {path === "/antoiler-create-account" ||
          path === "/all-customer-list-view-mobile" ? null : (
            <BreadcrumbLogic />
          )}
        </div>

        {/* Main Content */}
        <main className="flex-1">{children}</main>
      </div>
    </div>
  );
};

export default Layout;
