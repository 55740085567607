import React from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import SidebarItems from "./SideBarItems";
import {
  LogOut,
  Settings,
  Eye,
  EyeOff,
  Menu,
  X,
  PanelRightOpen,
} from "lucide-react";
import { useMutation } from "@tanstack/react-query";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../components/ui/dropdown-menu";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "../../../components/ui/dialog";

import { postData } from "../../../services/apiService";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { setBreadcrumbs } from "../../../common/Slice/BreadcrumbsSlice";
import { useDispatch } from "react-redux";

interface SideBarProps {
  isSidebarOpen: boolean;
  setIsSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SideBar: React.FC<SideBarProps> = ({
  isSidebarOpen,
  setIsSidebarOpen,
}) => {
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false,
  });
  const location = useLocation();
  const items = SidebarItems();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm();

  const { mutate, isPending } = useMutation({
    mutationFn: (data: any) => postData(`auth/change-password`, data),
    onSuccess: () => {
      toast.success("Password changed successfully");
      setModalOpen(false);
      reset();
    },
    onError: (error: any) => {
      toast.error("Error changing password");
      reset();
    },
  });

  const handleLogout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("filters");
    localStorage.removeItem("customerFilters");
    navigate("/");
  };

  const onSubmit = async (data: any) => {
    if (data.newPassword !== data.confirmPassword) {
      toast.error("New password and confirm password do not match");
      return;
    }
    const token = localStorage.getItem("access_token");
    mutate({ ...data, token });
  };

  const togglePasswordVisibility = (field: string) => {
    setShowPassword((prevState: any) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleNavigation = (title: string, url: string) => {
    const BreadCrumbObject: any = {
      title: title,
      url: url,
    };
    // Dispatch setBreadcrumbs when navigating
    dispatch(setBreadcrumbs(BreadCrumbObject));
    navigate(url);
  };

  return (
    <div
      className={`fixed flex flex-col justify-between h-full bg-white transition-all duration-300 ${
        isSidebarOpen ? "w-64 p-4" : "w-16 p-2"
      }`}
    >
      {/* Header Section */}
      <div className="flex justify-between items-center">
        {isSidebarOpen && <img src="/logo.png" alt="Logo" className="h-12" />}
        <button
          onClick={toggleSidebar}
          className="text-gray-700 focus:outline-none"
        >
          {isSidebarOpen ? <PanelRightOpen size={24} /> : <Menu size={24} />}
        </button>
      </div>

      {/* Navigation Items */}
      <ul className="mt-6 space-y-4">
        {items.map((item, index) => (
          <li key={index}>
            {"header" in item ? (
              isSidebarOpen && (
                <div className="text-gray-700 mb-1 text-sm ml-1">
                  {item.header}
                </div>
              )
            ) : (
              <NavLink
                to={item.path}
                onClick={() => handleNavigation(item.name, item.path)}
                className={`flex items-center p-1 rounded-md transition-colors duration-200 ${
                  location.pathname === item.path
                    ? "text-[#F15A24]"
                    : "text-gray-700"
                }`}
              >
                <div
                  className={`flex-shrink-0 text-center ${
                    isSidebarOpen ? "mr-3" : "w-full"
                  }`}
                >
                  {item.icon}
                </div>
                {isSidebarOpen && (
                  <span className="font-semibold">{item.name}</span>
                )}
              </NavLink>
            )}
          </li>
        ))}
      </ul>

      {/* Footer Section */}
      <div className="space-y-4 mt-auto">
        {/* Settings Dropdown */}
        {isSidebarOpen ? (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <div className="flex gap-4 items-center font-semibold text-gray-700 cursor-pointer">
                <Settings
                  size={17}
                  className="ml-2 group-hover:text-[#F15A24]"
                />
                <span className="-ml-1 group-hover:text-[#F15A24]">
                  Settings
                </span>
              </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuItem>Profile</DropdownMenuItem>
              <DropdownMenuItem>Menu</DropdownMenuItem>
              <DropdownMenuItem onSelect={() => setModalOpen(true)}>
                Change Password
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        ) : (
          <div className="flex justify-center items-center cursor-pointer">
            <Settings size={17} className="group-hover:text-[#F15A24]" />
          </div>
        )}

        {/* Logout */}
        <div
          className="flex items-center gap-4 font-semibold text-gray-700 cursor-pointer"
          onClick={handleLogout}
        >
          <LogOut size={17} className="ml-2 group-hover:text-[#F15A24]" />
          {isSidebarOpen && (
            <span className="-ml-1 group-hover:text-[#F15A24]">Logout</span>
          )}
        </div>
      </div>

      {/* Change Password Dialog */}
      <Dialog open={modalOpen} onOpenChange={setModalOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Change Password</DialogTitle>
          </DialogHeader>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="space-y-4">
              <div>
                <label className="block text-gray-700">Current Password</label>
                <div className="relative">
                  <input
                    type={showPassword.currentPassword ? "text" : "password"}
                    {...register("currentPassword", {
                      required: "Current password is required",
                    })}
                    className="w-full px-3 py-2 border rounded"
                  />
                  <div
                    className="absolute inset-y-0 right-3 flex items-center cursor-pointer"
                    onClick={() => togglePasswordVisibility("currentPassword")}
                  >
                    {showPassword.currentPassword ? <EyeOff /> : <Eye />}
                  </div>
                  {errors.currentPassword && (
                    <p className="text-red-500">
                      {errors.currentPassword.message?.toString()}
                    </p>
                  )}
                </div>
              </div>
              <div>
                <label className="block text-gray-700">New Password</label>
                <div className="relative">
                  <input
                    type={showPassword.newPassword ? "text" : "password"}
                    {...register("newPassword", {
                      required: "New password is required",
                      minLength: {
                        value: 8,
                        message: "New password must be at least 8 characters",
                      },
                    })}
                    className="w-full px-3 py-2 border rounded"
                  />
                  <div
                    className="absolute inset-y-0 right-3 flex items-center cursor-pointer"
                    onClick={() => togglePasswordVisibility("newPassword")}
                  >
                    {showPassword.newPassword ? <EyeOff /> : <Eye />}
                  </div>
                  {errors.newPassword && (
                    <p className="text-red-500">
                      {errors.newPassword.message?.toString()}
                    </p>
                  )}
                </div>
              </div>
              <div>
                <label className="block text-gray-700">
                  Confirm New Password
                </label>
                <div className="relative">
                  <input
                    type={showPassword.confirmPassword ? "text" : "password"}
                    {...register("confirmPassword", {
                      required: "Confirm password is required",
                      validate: (value) =>
                        value === getValues("newPassword") ||
                        "Passwords do not match",
                    })}
                    className="w-full px-3 py-2 border rounded"
                  />
                  <div
                    className="absolute inset-y-0 right-3 flex items-center cursor-pointer"
                    onClick={() => togglePasswordVisibility("confirmPassword")}
                  >
                    {showPassword.confirmPassword ? <EyeOff /> : <Eye />}
                  </div>
                  {errors.confirmPassword && (
                    <p className="text-red-500">
                      {errors.confirmPassword.message?.toString()}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <DialogFooter className="mt-6">
              <button
                type="button"
                onClick={() => setModalOpen(false)}
                className="px-4 py-2 border rounded bg-gray-200"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-[#F24E1E] text-white rounded"
                disabled={isPending}
              >
                {isPending ? "Changing..." : "Change Password"}
              </button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default SideBar;
