import React, { useState, useEffect } from "react";
import { Dialog, DialogContent } from "../../../components/ui/dialog";
import { Input } from "../../../components/ui/input";
import { Button } from "../../../components/ui/button";
import { toast } from "react-toastify";

const EditParentCategoryModal = ({ isOpen, close, category }: any) => {
  const [parentCategoryName, setParentCategoryName] = useState("");
  const [image, setImage] = useState<File | null>(null);

  // Initialize form data when category changes
  useEffect(() => {
    if (category) {
      setParentCategoryName(category.cat_title || "");
    }
  }, [category]);

  // Handle form submission
  const handleUpdate = () => {
    if (!parentCategoryName) {
      toast.error("Parent category name is compulsory");
      return;
    }
    console.log("Updating parent category:", parentCategoryName, image);
    close();
  };

  return (
    <Dialog open={isOpen} onOpenChange={close}>
      <DialogContent>
        <h2 className="text-xl font-semibold mb-4">Edit Parent Category</h2>

        {/* Parent Category Field */}
        <div className="mb-4">
          <label htmlFor="parentCategory" className="block mb-1">
            Parent Category
          </label>
          <Input
            type="text"
            id="parentCategory"
            placeholder="Enter Parent Category"
            value={parentCategoryName}
            onChange={(e) => setParentCategoryName(e.target.value)}
            className="w-full"
          />
        </div>

        {/* Image Upload Field */}
        <div className="mb-4">
          <label htmlFor="categoryImage" className="block mb-1">
            Upload Image
          </label>
          <Input
            type="file"
            id="categoryImage"
            accept="image/*"
            onChange={(e) => {
              const files = e.target.files;
              if (files && files.length > 0) {
                setImage(files[0]);
              }
            }}
            className="w-full"
          />
        </div>

        {/* Update Button */}
        <div className="flex justify-end">
          <Button onClick={handleUpdate} className="bg-red-500 text-white">
            Update
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default EditParentCategoryModal;
