import React, { useState, useEffect } from "react";
import { Dialog, DialogContent } from "../../../components/ui/dialog";
import { Input } from "../../../components/ui/input";
import { Button } from "../../../components/ui/button";
import { toast } from "react-toastify";
import Select from "react-select"; // Import React Select

const EditChildCategoryModal = ({ isOpen, close, category }: any) => {
  const [childCategoryName, setChildCategoryName] = useState("");
  const [selectedParentCategory, setSelectedParentCategory] = useState(null);
  const [image, setImage] = useState<File | null>(null);

  // Prepare options for the select dropdown
  //   const parentCategoryOptions = parentCategories.map((cat: any) => ({
  //     value: cat.id,
  //     label: cat.cat_title,
  //   }));

  // Initialize form data when category changes
  useEffect(() => {
    if (category) {
      setChildCategoryName(category.cat_title || "");
      //   const parentCat = parentCategories.find(
      //     (cat: any) => cat.id === category.parent_id
      //   );
    }
  }, [category]);

  // Handle form submission
  const handleUpdate = () => {
    if (!childCategoryName) {
      toast.error("Child category name is compulsory");
      return;
    }
    if (!selectedParentCategory) {
      toast.error("Parent category is compulsory");
      return;
    }

    console.log(
      "Updating child category:",
      childCategoryName,
      selectedParentCategory,
      image
    );
    close();
  };

  return (
    <Dialog open={isOpen} onOpenChange={close}>
      <DialogContent>
        <h2 className="text-xl font-semibold mb-4">Edit Child Category</h2>

        {/* Parent Category Select Field */}
        <div className="mb-4">
          <label htmlFor="parentCategory" className="block mb-1">
            Parent Category
          </label>
          <Select
            id="parentCategory"
            // options={parentCategoryOptions}
            value={selectedParentCategory}
            onChange={setSelectedParentCategory}
            placeholder="Select Parent Category"
          />
        </div>

        {/* Child Category Field */}
        <div className="mb-4">
          <label htmlFor="childCategory" className="block mb-1">
            Child Category
          </label>
          <Input
            type="text"
            id="childCategory"
            placeholder="Enter Child Category"
            value={childCategoryName}
            onChange={(e) => setChildCategoryName(e.target.value)}
            className="w-full"
          />
        </div>

        {/* Image Upload Field */}
        <div className="mb-4">
          <label htmlFor="categoryImage" className="block mb-1">
            Upload Image
          </label>
          <Input
            type="file"
            id="categoryImage"
            accept="image/*"
            onChange={(e) => {
              const files = e.target.files;
              if (files && files.length > 0) {
                setImage(files[0]);
              }
            }}
            className="w-full"
          />
        </div>

        {/* Update Button */}
        <div className="flex justify-end">
          <Button onClick={handleUpdate} className="bg-red-500 text-white">
            Update
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default EditChildCategoryModal;
