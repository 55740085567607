import { BASE_IMAGE, getData } from "../../../services/apiService";
import Loader from "../../../common/Loader/Loader";
import { format } from "date-fns";
import { Eye } from "lucide-react"; // Ensure you have lucide-react installed
import { MdOutlineModeEditOutline } from "react-icons/md";
import EditChildCategoryModal from "../components/EditChildCategoryModal";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";

const ChildCategoryView = ({ categories, isLoading }: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const { data } = useQuery({
    queryKey: ["getAllCategory"],
    queryFn: () => getData("category/get-all-categories-db"),
  });

  console.log(data?.data);

  // Function to format date
  const formatDate = (dateString: any) => {
    return dateString
      ? format(new Date(dateString), "dd MMM yy • hh:mm a")
      : "N/A";
  };

  const handleEditCategory = (category: any) => {
    setSelectedCategory(category);
    setIsModalOpen(true); // Open the modal
  };

  return (
    <div className="overflow-x-auto rounded-md shadow-md mt-6">
      {/* Show loader if loading, otherwise show table */}
      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <Loader />
        </div>
      ) : (
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-white">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-[#8E8E8E] uppercase tracking-wider">
                Child Category
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-[#8E8E8E] uppercase tracking-wider">
                Created At
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-[#8E8E8E] uppercase tracking-wider">
                Action
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {categories?.length >= 0 ? (
              categories.map((category: any, index: any) => (
                <tr key={index}>
                  <td className="px-6 py-4 whitespace-normal text-sm text-gray-500 flex items-center gap-4">
                    <img
                      src={`${BASE_IMAGE}${category?.cat_img}`}
                      className="w-10 h-10 rounded-full"
                      alt=""
                    />

                    {category.cat_title || "N/A"}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {formatDate(category.cat_date_time)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-black flex gap-3 items-center cursor-pointer">
                    {/* Eye Icon for Viewing */}
                    <div
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent the click event from bubbling up
                        // handleChildCategoryView(); // Call the function to handle view action
                      }}
                      className="hover:text-red-600" // Change color on hover
                    >
                      <Eye size={20} />
                    </div>

                    {/* Edit Icon for Editing */}
                    <div
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent the click event from bubbling up
                        // handleEditCategory(); // Call the function to handle edit action
                      }}
                      className="hover:text-green-600" // Change color on hover
                    >
                      <MdOutlineModeEditOutline size={20} />
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={3}
                  className="px-6 py-4 text-center text-sm text-gray-500"
                >
                  No categories available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      )}

      <EditChildCategoryModal
        isOpen={isModalOpen}
        close={() => setIsModalOpen(false)}
        category={selectedCategory}
      />
    </div>
  );
};

export default ChildCategoryView;
