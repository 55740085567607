import React, { useState } from "react";
import CategoryCreateForm from "../components/CategoryCreateForm";
import { useQuery } from "@tanstack/react-query";
import { getData } from "../../../services/apiService";
import Loader from "../../../common/Loader/Loader";
import SearchBar from "../../../common/SearchBar/SearchBar";
import CreateCategoryAdvanceFilter from "../components/CreateCategoryAdvanceFilter";
import { Button } from "../../../components/ui/button"; // For modal trigger
import { Dialog, DialogContent } from "../../../components/ui/dialog"; // For modal
import CategoryTable from "../components/CategoryTable";

const CreateCategory: React.FC = () => {
  const { data, isLoading } = useQuery({
    queryKey: ["parentCategory"],
    queryFn: () => getData("category/parent-category"),
  });

  const [isModalOpen, setModalOpen] = useState(false); // Control modal state

  const handleSearchBar = (value: string) => {
    console.log(value);
  };

  const handleApplyFilters = (value: number) => {
    console.log(value);
  };

  const handleModalToggle = () => {
    setModalOpen(!isModalOpen); // Toggle modal state
  };

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {/* Search bar and filters */}
          <SearchBar
            onChange={handleSearchBar}
            placeholder="Search based on category"
          />
          {/* <CreateCategoryAdvanceFilter onApplyFilters={handleApplyFilters} /> */}

          {/* Button to trigger modal */}
          <div className="flex justify-end items-end">
            <Button
              onClick={handleModalToggle}
              className="bg-orange-500 text-white mt-4 "
            >
              Create New Category
            </Button>
          </div>

          <Dialog open={isModalOpen} onOpenChange={setModalOpen}>
            <DialogContent className="max-w-2xl overflow-y-auto">
              <CategoryCreateForm
                parentCategory={data?.data}
                isModalOpen={isModalOpen}
                closeModal={() => setModalOpen(false)}
              />
            </DialogContent>
          </Dialog>

          <CategoryTable categories={data?.data} />
        </>
      )}
    </div>
  );
};

export default CreateCategory;
